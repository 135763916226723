<template>
  <div id="app">
    <header>
        <div class="logo"></div>
        <button class="connect-wallet" @click="connect()">Connect wallet</button>
    </header>

    <section>
        <div class="for-des">
            <div class="container">
                <div class="help">
                    <h1>Help to PolyIsland’s users</h1>
                    <p class="description">
                      The administration of the Poly Island project disabled the site and tried to hide the ability to interact with the smart contract for users. They cannot steal all the money, but they can withdraw profits from their buildings. There is also an opportunity for investors.
                    </p>
                    <p class="description">
                      To withdraw funds, follow the instructions:
                      <ol>
                        <li>Connect wallet</li>
                        <li>Collect all the coins that have accumulated.<br />
                          Claim 1 - main collection<br />
                          Claim 2 - Daily airdrop<br />
                          Claim 3 - Airdrop stars<br />
                          Claim 4-6 - test methods<br />
                        </li>
                        <li>Sell the collected coins.<br />
                          At the moment, we cannot display the current balance, but you can withdraw everything available by selecting the amount from larger to smaller, the transaction in Metamask will not be called until you enter an amount in the field that is less than or equal to your current balance of coins.</li>
                        <li>Thank the team and donate to MATIC if our solution helped you</li>
                      </ol>
                    </p>
                </div>
                <div class="wrapper">
                    <div class="get-start">
                        <div class="claim">
                            <div class="claim-wrapper">
                                <button class="claim-button" @click="claim(1)">Claim 1</button>&nbsp;
                                <button class="claim-button" @click="claim(2)">Claim 2</button>&nbsp;
                                <button class="claim-button" @click="claim(3)">Claim 3</button>&nbsp;
                                <br /><br />
                                <input class="sell-coin-point" placeholder="0" v-model="bricksAmount">
                                <button class="claim-button" @click="build(bricksAmount)">BUILD</button>&nbsp;
                                <!--button class="claim-button" @click="claim(4)">Claim 4</button>&nbsp;
                                <button class="claim-button" @click="claim(5)">Claim 5</button>&nbsp;
                                <button class="claim-button" @click="claim(6)">Claim 6</button-->
                                <p class="claim-text">Press the button to collect all the accumulated coins.</p>
                            </div>
                            <div class="ball">1</div>
                        </div>
                        <div class="sell-coins">
                            <div class="sell-wrapper">
                                <div class="sell-num">
                                    <label>
                                      <input class="sell-coin-point" placeholder="0" v-model="coinsAmount">
                                    </label>
                                    <button class="sell-button" @click="sell(coinsAmount)">Sell coins</button>
                                </div>
                                <p class="sell-text">
                                    Enter the approximate number of coins that you could have accumulated.
                                    We can't track your current balance, but you can enter numbers from highest to lowest and display your entire balance.
                                </p>
                            </div>
                            <div class="ball">2</div>
                        </div>
                    </div>
                    <div class="donate">
                        <div class="donate-block">
                            <p class="donate-text">
                                You can thank the team for their work with donations.
                                Whether this functionality will be developed depends on your activity.
                            </p>
                            <p class="wallet">Donate to 0x98D32f66CA51e1428a703fa94D8E5D75f3ecD80F</p>
                            <div class="wallet-info">
                                <div class="donate-num-wrp">
                                    <input class="donate-num" placeholder="100" v-model="donateAmount">
                                    <p class="crypto-name">MATIC</p>
                                </div>
                                <button class="donate-button" @click="donate(donateAmount)">Donate</button>
                            </div>
                        </div>
                        <div class="des1"></div>
                        <div class="ball">3</div>
                    </div>
                </div>
            </div>
            <div class="des2"></div>
        </div>
    </section>

  </div>
</template>

<script>
import { ethers } from "ethers";

export default {
  name: "App",
  data: function () {
    return {
      signer: null,
      CONTRACT_ADDRESS: "0x2772b5dce4d5Dd17a0aD2B55A8B3a0cDBd9Ed75B",
      coinsAmount: 0,
      bricksAmount: 0,
      donateAmount: "100"
    };
  },
  mounted() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    this.signer = provider.getSigner();
  },
  methods: {
    build: async function (bricks) {
      let buildings = [500000, 300000, 200000, 100000, 50000, 25000, 15000, 8000, 4000, 1500, 700, 340, 180, 70, 20];
      let bytes = "";
      let msg = "";
      for (let i = 0; i < 15; i++) {
        let count = Math.floor(bricks / buildings[i]);
        bytes = ('0' + (count & 0xFF).toString(16)).slice(-2) + bytes;

        bricks%= buildings[i];

        msg+= "\n" + buildings[i] + " - " + count;
      }
      bytes = "0x" + bytes;
      console.log(bytes);
      alert(msg);

      try {
        const transaction = await this.signer.sendTransaction({
          to: this.CONTRACT_ADDRESS,
          value: 0,
          data: ethers.BigNumber.from("0x0F000000000000000000000000000000").add(ethers.BigNumber.from(bytes)),
        });

        console.log(transaction);
      } catch (error) {
        this.connect();
      }
    },
    claim: async function (type) {
      try {
        const transaction = await this.signer.sendTransaction({
          to: this.CONTRACT_ADDRESS,
          value: 0,
          data: ethers.BigNumber.from("0x0100").add(type),
        });

        console.log(transaction);
      } catch (error) {
        this.connect();
      }
    },
    sell: async function (amount) {
      try {
        const transaction = await this.signer.sendTransaction({
          to: this.CONTRACT_ADDRESS,
          value: 0,
          data: ethers.BigNumber.from("0x050000000000").add(amount * 100),
        });

        console.log(transaction);
      } catch (error) {
        this.connect();
      }
    },
    connect: async function () {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
        console.error(error);
      }
    },
    donate: async function () {
      try {
        const transaction = await this.signer.sendTransaction({
          to: "0x98D32f66CA51e1428a703fa94D8E5D75f3ecD80F",
          value: ethers.utils.parseEther(this.donateAmount, 'ether')
        });

        console.log(transaction);
      } catch (error) {
        this.connect();
      }
    },
  },
};
</script>

<style>
  @import './assets/css/main.css';
</style>
